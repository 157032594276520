<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-allowance :allowances="allowances" @editForm="editForm" @refresh="refresh"/>
            <modal-tariff-allowances @refresh="refresh"/>
            <modal-tariff-allowances-edit :id="id" @refresh="refresh"/>
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableAllowance from '@/views/component/Table/tableTariffAllowances.vue'
import ModalTariffAllowances from '@/views/component/Modal/ModalTariffAllowances/ModalTariffAllowance.vue'
import ModalTariffAllowancesEdit from '@/views/component/Modal/ModalTariffAllowances/ModalTariffAllowancesEdit.vue'
export default {
    components: {
        tableAllowance,
        ModalTariffAllowances,
        ModalTariffAllowancesEdit,
    },
    data(){
        return {
            allowances: [],
            showPreloader: false,
            id: null,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '10px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '20px'}},
                { key: 'name', label: 'Надбавки', sortable: true, thStyle: {width: '150px'}},
                { key: 'prices', label: 'Цена', sortable: true, thStyle: {width: '150px'}},
      ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        editForm(id){
            this.id = id.id
            setTimeout(() => {this.$bvModal.show('TariffAllowanceEdit')},0);
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get(`${this.$route.name}`)
                .then(res => {
                    this.allowances = res.data
                    this.$store.commit('pageData/setdataCount', this.allowances.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                }).catch(err => {})
        },
        getDataAllowance() {
            this.$http
                .get(`${this.$route.name}/data`)
                .then(res => {
                    this.allowanceData = res.data
                })
        },
        sendToParent(tableData){
            this.allowances = tableData
            this.$store.commit('pageData/setdataCount', this.allowances.length)
        },
        openFilter(){
                let allRoutes = this.$store.state.draggableTab.tabs
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                let arrayRoutes = []
                allRoutes.forEach(element => {
                    arrayRoutes.push(element.path)
                });
                if (arrayRoutes.includes(this.$route.path) == true) {
                    this.showPreloader = true
                    this.$http
                        .get(`${this.$route.name}`, {params: param}).then(res => {
                            this.allowances = res.data
                            this.$store.commit('pageData/setdataCount', this.allowances.length)
                            this.showPreloader = false
                            this.$store.commit('REFRESH_DATA', false)
                        })
                }else if(arrayRoutes.includes(this.$route.path) == false){
                    this.$bvModal.show(this.$route.name + 'filter')
                }
            },
        },
        computed: {
            fetchingNewData(){
                return this.$store.state.refresh.fetchingNewData
            }
        },
        watch: {
            fetchingNewData(val){
                if (val){
                    this.$http
                    .get(`${this.$route.name}`)
                    .then(res =>{
                        this.allowances = res.data
                        this.$store.commit('pageData/setdataCount', this.allowances.length)
                        this.$store.commit('REFRESH_DATA', false)
                    })
                    }
                }
        }
}
</script>